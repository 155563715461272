<template>
	<layout-div>
		<div class="card">

			<input v-model="model.id" type="hidden" class="form-control" id="id" name="id" />

			<div class="card-header">
				<button @click="handleSave()" :disabled="isSaving" type="button" class="btn btn-info mt-3">
					儲存
				</button>

				<router-link class="btn btn-default mt-3 float-right" :to="`/admin/Document/index/${code}`">
					返回列表
				</router-link>
			</div>
			<div class="card-body">
				<div class="form-horizontal col-md-8">

					<div class="form-group row">
						<label htmlFor="housecode" class="col-sm-2 col-form-label">案場</label>
						<div class="col-sm-10">
							<input v-model="model.housecode" type="text" class="form-control" id="housecode"
								name="housecode" />
						</div>
					</div>

					<div class="form-group row">
						<div class="input-group">
							<input type="file" name="file" id="doc" ref="file" @change="handleFileUpload(this)"
								capture />

						</div>
					</div>

					<div class="form-group row">
						<label htmlFor="printCount" class="col-sm-3 col-form-label">預設列印份數</label>
						<div class="col-sm-4">
							<input v-model="model.printCount" type="number" class="form-control" id="printCount"
								name="printCount" />
						</div>
					</div>

				</div>
			</div>

		</div>
	</layout-div>
</template>

<script>
import LayoutDiv from '@/components/LayoutDiv.vue'
import { addDocument } from '@/api/Document.js'
import $ from 'jquery'

export default {
	name: 'ProjectCreate',
	components: {
		LayoutDiv
	},
	data() {
		return {
			model: {
				housecode: '',
				printCount: 1,
				filename: '',
				file: ''
			},
			code: '',
			isSaving: false,
		};
	},
	created() 
    {
        this.code = this.$route.params.code;
    },
	methods: {
		handleFileUpload() {
			this.model.file = document.querySelector('#doc').files[0];
			this.model.filename = $('#doc').val();
		},

		handleSave() {
			this.isSaving = true;

			addDocument(this.model).then(rep => {

				this.isSaving = false;
				this.reset();
				return rep;
			})
				.catch(error => {
					this.isSaving = false
					return error
				});
		},
		reset() {
			this.model.id = "";
			this.model.housecode = "";
			this.model.filename = "";
			this.model.ext = "";
			this.model.realfile = "";
			this.model.printCount = "";
			this.model.editor = "";
			this.model.update_time = "";

		}
	}
};
</script>
